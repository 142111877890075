.thanks-message-wrapper {
    text-align: center;
    padding: 40px 0;
    background: #fff;
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.thanks-message-wrapper .card {
    width: 100%;
    max-width: 680px;
    background: #c9cdd124;
    padding: 50px 20px;
}

.thanks-message-wrapper .div_class i {
    font-size: 58px;
    color: green;
    position: relative;
    left: -8px;
}

.thanks-message-wrapper .div_class {
    display: inline-block;
    border: 1px solid green;
    width: 90px;
    height: 90px;
    border-radius: 50%;
}

.thankyou-payment-grid {
    display: flex;
    justify-content: space-between;
    background: #cfd2d54f;
    align-items: center;
    padding: 10px 20px;
    margin-top: 30px;
}

.thankyou-payment-grid p {
    margin: 0;
    padding: 0;
}