
.team{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 1rem 0rem;
    margin:  1rem 4rem;

    border: 1px solid whitesmoke;
    border-radius:10px;

}
.team-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
 
  
    flex-wrap: wrap;
   
}
/* .main-container{
    width: 100%;
    display: flex;
    flex-direction: row;
} */
.page-heading{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;


}

.page-heading>h1{
    margin-left: 10px;
}



@media screen and (max-width: 525px) {
   
    .team{
        margin: 30px;
    }
    .page-heading{
        text-align: center;
    }
 
   }