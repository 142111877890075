.course-details-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
}

.left-card {
    width: 810px;
    margin: 40px;
    margin-right: 24px;
}

.right-card-course {
    min-width: 360px;
    width: 450px !important;
    margin: 40px;
    margin-left: 0px;
}
.course-description {
    line-height: 1.5rem;
    font-weight: 500;
}
.right-card-content {
}
#payBtn:hover {
    opacity: 0.9;
}
@media screen and (max-width: 868px) {
    .course-details-container {
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    .right-card-course {
        margin-left: 40px;
    }
}

@media screen and (max-width: 525px) {
    .left-card,
    .right-card-course {
        margin: 30px;
    }

    .right-card-course {
        min-width: 300px;
    }
}
