.footer{
    width:100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;

}

.footer>p{
 font-size: 14px;
 font-weight: 500;
 color: grey;
}

