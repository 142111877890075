.childprofile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 1rem 0rem;
    margin: 1rem 4rem;

    border: 1px solid whitesmoke;
    border-radius: 10px;
}
.childprofile-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.page-heading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Childform {
    width: 60%;
    background-color: rgb(250, 249, 249);
    padding: 30px;
}
.form-top {
    height: 70px;
}

.form-top > img {
    height: 50px;
}

p {
    font-size: 17px;
    font-family: 'Open Sans';
}
.mt20 {
    margin-top: 20px;
}

input {
    margin-top: 5px;
    padding: 5px;
    min-width: 240px;
    margin-right: 10px;
    line-height: 1.2em;
}
.inp250 {
    min-width: 505px;
}
textarea {
    min-width: 99%;
    max-width: 99%;
    min-height: 100px;
    max-height: 140px;
    margin-top: 5px;
    padding: 5px;
}
.red {
    color: red;
}
.input-container {
    display: flex;
    flex-direction: column;
}

/* .SubmitBtn {
    width: 120px;
    padding: 8px 12px;
    color: white;
    background: red;
} */
.submitbtn-inside {
    float: right;
    margin-top: 140px;
}
.childheading {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}
.contact-link-style {
    color: #0099ff !important;
}
.contact-link-style:hover {
    color: #2baaff !important;
}
@media screen and (max-width: 525px) {
    .childprofile {
        margin: 30px;
    }
    .page-heading {
        text-align: center;
    }
    .submitbtn-inside {
        float: right;
        margin-top: 10px;
    }
}
@media (min-width: 526px) and (max-width: 768px) {
    .submitbtn-inside {
        float: right;
        margin-top: 10px;
    }
}
@media (min-width: 769px) and (max-width: 899px) {
    .submitbtn-inside {
        float: right;
        margin-top: 10px;
    }
}
@media (min-width: 900px) and (max-width: 1050px) {
    .submitbtn-inside {
        float: none;
        margin-top: 10px;
        display: flex;
        margin-left: 10px;
    }
}
