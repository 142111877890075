

.card-btn{
  width:100%;
    display: flex;
    justify-content:center;
    align-items:center;
    color: white;
}

a{
    text-decoration: none;
}

#classbtnn{
  width:100%;
  padding:8px 0px;

}


#btnbg:hover{
opacity: 0.9;

}
