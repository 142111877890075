.card-container {
    min-width: 370px;
    max-width: 380px;
    min-height: 180px;
    display: flex;

    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    align-items: flex-start;

    margin: 8px;

    border: solid 1px #e4eaf0;

    padding: 15px;
}

.card-container:hover {
    background-color: #faecf5;
}

.avatar > img {
    width: 74px;
    height: 74px;

    object-fit: cover;
    border-radius: 50%;

    margin-right: 15px;
}

.details {
    color: #33344a;
}

.details > h3 {
    margin-bottom: 0px;
    margin-top: 0;
    font-family: 'Open Sans';
    font-weight: 700;
}

.details > p {
    margin-top: 8px;
    font-size: 17px;
    /* font-weight: 400; */
}

a {
    text-decoration: none;
}

@media screen and (max-width: 890px) {
    .card-container {
        min-width: 500px;
        max-width: 90%;
    }
}

@media screen and (max-width: 650px) {
    .card-container {
        min-width: 374px;
        max-width: 90%;
    }
}

@media screen and (max-width: 650px) {
    .card-container {
        min-width: 320px;
        max-width: 90%;
    }
}

@media screen and (max-width: 390px) {
    .card-container {
        min-width: 90%;
        max-width: 90%;
    }
}
