.class-details-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
}

.left-card {
    width: 810px;
    margin: 40px;
    margin-right: 24px;
}

.right-card {
    min-width: 360px;
    width: 360px;
}

.details-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid whitesmoke;
}

.pricingCard {
    margin-top: 20px;
}

.right-card-heading {
    width: 100%;
    padding: 8px 0px;
    font-family: 'Open Sans';
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
}

#contact-classbtn {
    width: 50%;
    padding: 8px 0px;
}

#contact-classbtn:hover {
    opacity: 0.9;
}
@media screen and (max-width: 868px) {
    .class-details-container {
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
}

@media screen and (max-width: 525px) {
    .left-card,
    .right-card {
        margin: 30px;
    }
    .right-card {
        min-width: 300px;
    }
}
