.App{
    width:100vw;
    min-height: 100vh; 
    box-sizing: border-box;
    overflow-x:hidden;
}


.header-component{
    min-height:calc(100vh - 100px - 70px);
}
