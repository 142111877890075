
  .header {
    display: flex;
    flex-direction: row;
    height:calc(100vh - 100px - 70px);
    background-color: rgb(255, 249, 241);
    position: relative;
    box-sizing: border-box;
}


.header-image {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    box-sizing: border-box;

}

.header-image img {
    height: 100%;
}

.header-container {
    z-index:5;
    width: 40%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.header-content{
    margin-right: 6rem;
    font-family: 'Open Sans';
    box-sizing: border-box;
    margin-top: 3rem;
}
.header-content h1 {
    font-size: 56px;
    font-weight:bold;
    margin-bottom:14px;
    color:black;
    letter-spacing: -0.04em;

}

.header-content h4 {
    color:black;
    font-size:20px;
    margin:8px 0;

    letter-spacing: -0.04em;
}

.instagramLogo{
    height: 50px;
    width: 50px;
    margin-right: 10px;


}

.logo-group{
    display: flex;
    position: absolute;
    right: 4%;
    top: 10%;
    justify-content: end;
    justify-items: center;
    gap: 10px;
    /* margin-right: -280px; */
    

}
.rightdiv{
    position: relative;
}

.logo-group img:hover{
 background-color: brown;
 border-radius: 50%;
padding: 1px;


}
@media screen and (max-width: 1505px) {
    .header-content{
        margin-right: 4rem;
        /* margin-top:-1rem; */

    }

    .header-image img {
        height: 95%;
    }
    .logo-group{
        top:2rem;
        
        
    
    }
   
}

@media screen and (max-width: 1225px) {
    .header-image img {
        height: 90%;
    }
    .header-content h1 {
        font-size: 48px;
    }   
    .logo-group{
       top:1rem;
        
    }   
    .header-content{
        /* margin-top: 4rem; */
    }
}

@media screen and (max-width: 1045px) {
    .header-image img {
        height: 88%;
    }
    .header-container {
        width: 60%;
    }
    .header-content{
        margin-right: 3rem;
        /* margin-top: 3rem; */
    }

    .header-content h1 {
        font-size: 42px;
    } 
    .logo-group{
        
     top: 3rem;
     right: 37%;
        
    }   
}

@media screen and (max-width: 825px) {

    .header {
        flex-direction: column-reverse;
        min-height:calc(100vh - 100px - 70px);
        height: auto; 
    }
    .header-container, .header-image{
        width: 100%;
    }

    .header-content h1 {
        margin-top: 60px;
        font-size: 62px;
    }   
    .header-content h2{
        font-size: 32px;
    } 

    .header-content h4{
        font-size: 24px;
    } 
    .header-image img {
        position: relative;
        height: 480px;
        left: -20px;
    }
    .header-content {
        margin-right: 0;
  margin-bottom: 35px;
  padding: 0px 25px;
  
    }  
    /* .logo-group{
        
        margin-right: 0px;
        margin-top:4rem;
    
    } */
  
}

@media screen and (max-width: 650px) {

    .header-image img {
        position: relative;
        height: 380px;
        left: -20px;
    }
    .header-content {
        margin-right: 0;
        margin-bottom: 24px;
    }

    .header-content h1 {
        font-size: 56px;
    }
    .header-content h2 {
        font-size: 32px;
    }
    .header-content h4 {
        font-size: 24px;
    }

    .logo-group{
        
        display: flex;
        position: absolute
        ;
        right: 38%;
    
        top: 4%;
    
    }
}

@media screen and (max-width: 490px) {
    .header-content h1 {
        font-size: 48px;
    }
    .header-content h2 {
        font-size: 24px;
    }
    .header-content h4 {
        font-size: 18px;
    }
    .header-image img {
        height: 320px;
        left: 0px;
    }
    .logo-group{
        
        display: flex;
        position: absolute
        ;
        right: 33%;
    
        top: 5%;
    
    }
}