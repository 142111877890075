.teamdetails {
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
    justify-content: flex-start;
    align-items: flex-start;

    /* padding: 1rem 0rem; */
    margin: 1rem 4rem;

    border: 1px solid whitesmoke;
    border-radius: 10px;

    padding: 4rem 6rem;
}

.details-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    /* justify-content: space-between; */

    margin-top: 2rem;
}

.left-avatar {
    max-width: 240px;
    margin-right: 50px;
}

.left-avatar > img {
    /* width: 100%; */
    width: 150px;
    height: auto;
}

.right-details {
    width: 100%;
}

.right-details > h3 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 26px;
    padding-bottom: 10px;
    line-height: 1em;
}
#lastP {
    padding-top: 8px;
}

span {
    font-weight: 300;
    /* font-weight: 700; */

    margin-right: 5px;
}

p {
    font-size: 17px;
    font-family: 'Open Sans';
}

@media screen and (max-width: 850px) {
    .teamdetails {
        margin: 30px;
        padding: 50px;
    }
    .left-avatar {
        margin-right: 0px;
        max-width: 90%;
    }
    .details-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .right-details {
        margin-top: 20px;
    }
}

@media screen and (max-width: 525px) {
    .teamdetails {
        margin: 30px;
        padding: 20px;
    }
}
