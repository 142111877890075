.resourceDetails {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 1rem 0rem;
    margin: 1rem 4rem;

    /* border: 1px solid whitesmoke; */
    border-radius: 10px;
}
.resourceDetails-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    flex-wrap: wrap;
}

.page-heading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.page-heading > h1 {
    margin-left: 10px;
}

.resourceDetails-container > img {
    height: 350px;
    object-fit: cover;
}

@media screen and (max-width: 800px) {
    .MuiPaper-root {
        width: 500px;
    }
    .resourceDetails {
        margin: 30px;
    }
}

@media screen and (max-width: 630px) {
    .MuiPaper-root {
        width: 300px;
    }
}
