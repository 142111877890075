.timetable {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem;
    margin: 1rem 4rem;
    border: 1px solid whitesmoke;
    border-radius: 10px;
}
.timetable-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: auto;
}

.page-heading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.page-heading > h1 {
    margin-left: 10px;
}
.timetable-display-table {
    display: block;
}
.timetable-display-card {
    display: none;
}
@media screen and (max-width: 1350px) {
    .timetable-container {
        width: 100%;
        overflow-x: scroll;
    }
}

@media screen and (max-width: 525px) {
    .timetable {
        margin: 25px;
    }
    .page-heading {
        text-align: center;
    }
    .timetable-display-table {
        display: none;
    }
    .timetable-display-card {
        display: inline-flex;
    }
}
@media screen and (max-width: 820px) {
    .timetable {
        margin: 25px;
        margin-bottom: 10px;
    }
    .page-heading {
        text-align: center;
    }
    .timetable-display-table {
        display: none;
    }
    .timetable-display-card {
        display: inline-flex;
    }
}
