.childprofile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 1rem 0rem;
    margin: 1rem 4rem;

    border: 1px solid whitesmoke;
    border-radius: 10px;
}
.childprofile-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.page-heading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Childform {
    width: 60%;
    background-color: rgb(250, 249, 249);
    padding: 30px;
}
.form-top {
    height: 70px;
}

.form-top > img {
    height: 50px;
}

p {
    font-size: 17px;
    font-family: 'Open Sans';
}
.mt20 {
    margin-top: 20px;
}

input {
    margin-top: 5px;
    padding: 5px;
    min-width: 280px;
    margin-right: 10px;
    line-height: 1.2em;
}
.inp250 {
    min-width: 505px;
}
textarea {
    min-width: 99%;
    max-width: 99%;
    min-height: 100px;
    max-height: 140px;
    margin-top: 5px;
    padding: 5px;
}
.red {
    color: red;
}
.input-container {
    display: flex;
    flex-direction: column;
}

.btn-class {
    width: 120px;
    padding: 8px 12px;
    color: white;
    background: red;
}
.submitbtn-inside {
    float: right;
    margin-top: 140px;
}
.childheading {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}
.contact-link-style {
    color: #0099ff !important;
}
.contact-link-style:hover {
    color: #2baaff !important;
}

input[type='file'] {
    /* min-width: 45%; */
    max-width: 100% !important;
    color: #444;
    padding: 10px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #0099ff;
    cursor: pointer;
    margin-left: 10px;
}

input[type='file']::file-selector-button {
    margin-right: 20px;
    border: none;
    background: #0099ff;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

input[type='file']::file-selector-button:hover {
    background: #0099ff;
}
@media screen and (max-width: 525px) {
    .childprofile {
        margin: 30px;
    }
    .page-heading {
        text-align: center;
    }
    input[type='file'] {
        min-width: 88%;
        max-width: 100% !important;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 5px;
    }
    .submitbtn-inside {
        float: right;
        margin-top: 10px;
    }
}
@media (min-width: 526px) and (max-width: 768px) {
    input[type='file'] {
        min-width: 90%;
        max-width: 100% !important;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 5px;
    }
    .submitbtn-inside {
        float: right;
        margin-top: 10px;
    }
}
@media (min-width: 769px) and (max-width: 899px) {
    input[type='file'] {
        min-width: 96%;
        max-width: 100% !important;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 5px;
    }
    .submitbtn-inside {
        float: right;
        margin-top: 10px;
    }
}
@media (min-width: 900px) and (max-width: 1050px) {
    input[type='file'] {
        min-width: 26%;
        max-width: 100% !important;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 5px;
    }
    .span-risponsice-css {
        display: block;
    }
    .submitbtn-inside {
        float: none;
        margin-top: 10px;
        display: flex;
        margin-left: 10px;
    }
}
@media (min-width: 1051px) and (max-width: 1200px) {
    input[type='file'] {
        min-width: 26%;
        max-width: 100% !important;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 5px;
        margin-left: 0px;
    }
    input {
        min-width: 280px !important;
    }
}
