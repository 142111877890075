.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    height: 80px;
    max-height: 80px;
    margin-top: 1.2rem;
    padding: 0 4rem;
    padding-bottom: 16px;
    align-items: center;
    border-bottom: 5px solid;
    border-image: linear-gradient(
            to right,
            #f27b81 14.28%,
            #f7c44e 14.28%,
            #f7c44e 28.56%,
            #aec158 28.56%,
            #aec158 42.84%,
            #b0b6fb 42.84%,
            #b0b6fb 57.12%,
            #f7c44e 57.12%,
            #f7c44e 71.4%,
            #dd72b3 71.4%,
            #dd72b3 85.68%,
            #fcc6e6 85.68%
        )
        5;
}

.nav-container {
    display: flex;
    flex-direction: column;
    margin: 0;
    text-align: center;
}

.top-nav-container,
.bottom-nav-container {
    display: flex;
    flex-direction: row;
    margin: 0;
    text-align: center;
    list-style: none;
    flex-wrap: wrap;
}

.logo:hover {
    opacity: 0.7;
}

.nav-container li {
    display: inline-block;
    margin: 0 5px;
    font-size: large;
    font-weight: 600;
}

.nav-container li a {
    display: block;
    text-decoration: none;
    color: black;
    font-family: 'Open Sans', sans-serif;
    padding: 7px 11px;
    position: relative;
    z-index: 1;
}

.nav-container li a,
.nav-container li a:after,
.nav-container li a:before {
    transition: all 0.5s;
}

.nav-container li a:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 1px;
    content: '.';
    color: transparent;
    visibility: none;
    opacity: 0;
    z-index: -1;
}

.top-nav-container {
    justify-content: flex-end;
}

.top-nav-container > li > a {
    font-size: medium;
    font-weight: 400;
    color: gray;
}
.top-nav-container > li > a:hover {
    color: black;
}
.pink:after {
    background-color: pink;
}

.nav-container li a:hover:after {
    opacity: 1;
    visibility: visible;
    height: 100%;
}

/* hamburger */
.right .hamburger {
    width: 35px;
    height: 28px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}

.right .hamburger span {
    z-index: 20;
    width: 100%;
    height: 3px;
    background-color: black;
    transform-origin: left;
    transition: all 0.5s ease;
}

.right.active .hamburger span:first-child {
    background-color: black;
    transform: rotate(45deg);
}

.right.active .hamburger span:nth-child(2) {
    opacity: 0;
}

.right.active .hamburger span:last-child {
    background-color: black;
    transform: rotate(-45deg);
}

.hamburger span {
    color: white;
}

/* menu  */

.menu {
    width: 300px;
    height: 100vh;
    background-color: whitesmoke;
    position: fixed;
    top: 0;
    right: -300px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 1s ease;
}

.menu.active {
    right: 0;
}

.menu ul {
    margin: 40px;
    padding: 0;
    list-style: none;
    font-size: 17px;
    font-weight: 700;
    color: black;
    width: 70%;
}

.menu ul li {
    margin-bottom: 10px;
    line-height: 40px;
    border-bottom: 1px solid lightgray;
}

.menu ul li a {
    font-size: inherit;
    color: inherit;
    text-decoration: none;
}

.menu ul li:hover {
    font-weight: 500;
}

.right,
.menu {
    display: none;
}

@media screen and (max-width: 1024px) {
    .logo > a > img {
        height: 64px;
    }

    .navbar {
        padding-left: 50px;
        padding-right: 50px;
    }

    .nav-container li a {
        padding: 4px 8px;
    }

    .bottom-nav-container {
        padding-left: 20px;
    }
}

@media screen and (max-width: 950px) {
    .logo > a > img {
        height: 60px;
    }

    .navbar {
        padding-left: 40px;
        padding-right: 40px;
    }

    .nav-container li {
        font-size: medium;
    }

    .nav-container li a {
        padding: 2px 5px;
    }

    .bottom-nav-container {
        padding-left: 10px;
    }
}

@media screen and (max-width: 772px) {
    .nav-container {
        display: none;
    }

    .right,
    .menu {
        display: flex;
    }
}

@media screen and (max-width: 525px) {
    .navbar {
        padding: 30px;
    }
}
