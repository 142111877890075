.testimonials {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 1rem 0rem;
    margin: 1rem 4rem;

    border: 1px solid whitesmoke;
    border-radius: 10px;
}
.testimonials-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    flex-wrap: wrap;
}

.page-heading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.page-heading > h1 {
    margin-left: 10px;
}
.Learn-more-hover:hover {
    /* background-color: #7bc9fdd4 !important; */
    text-decoration: none;
    background-color: #8fdff5 !important;
}
@media screen and (max-width: 525px) {
    .testimonials {
        margin: 30px;
    }
    .page-heading {
        text-align: center;
    }
}
