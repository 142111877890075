.gallery {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem;
    margin: 1rem 4rem;
    border: 1px solid whitesmoke;
    border-radius: 10px;
}
.gallery-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.gallery-container .gallery-card {
    box-shadow: none;
    border-radius: 5px;
    border: 1px solid #00000012;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.gallery-card:hover{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.gallery-content {
    background: #f1f2f97a;
    padding: 9px 10px;
}

.gallery-content h6 p {
    margin-bottom: 0;
    margin-top: 5px;
}

.gallery-content h4 {
    color: #E91E63;
}


.gallery-container .gallery-img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    cursor: zoom-in;
}

.page-heading > h1 {
    margin-left: 10px;
}

@media screen and (max-width: 525px) {
    .gallery {
        margin: 30px;
    }
    .page-heading {
        text-align: center;
    }
}
