.about-heading-box {
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.about-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem;
    margin: 1rem 4rem;
    border: 1px solid whitesmoke;
    border-radius: 10px;
}

.about-bottom-box {
    display: flex;
    justify-content: center;
}
.about-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: auto;
    max-width: 1300px;
    margin-top: 25px;
    /* flex-wrap: wrap; */
}
.about-left-col {
    padding: 25px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 40%;
    /* width: 345px; */
}
.about-left-img {
    width: 100%;
    height: auto;
}
.about-right-col {
    padding: 25px;
    width: 60%;
    display: flex;
    flex-direction: column;
}
.about-right-col h4 {
    margin-bottom: 0px;
    margin-top: 0px;
}
/* .about-right-col p {
    color: #858d9f;
    margin-top: 20px;
    font-size: 16pxs;
} */
.about-right-col h2 {
    margin: 0px;
    padding-top: 5px;
}
@media (max-width: 575px) {
    .about-container {
        margin: 30px;
    }
    .about-bottom-border {
        width: 50%;
        height: auto;
    }

    .about-row {
        margin-top: 30px;
        flex-wrap: wrap;
    }
    .about-left-col {
        width: 100%;
    }
    .about-right-col {
        padding: 25px;
        width: 100%;
    }
    .about-right-col h4 {
        text-align: center;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .about-right-col h2 {
        text-align: center;
    }
}
@media (min-width: 576px) and (max-width: 991px) {
    .about-container {
        margin: 30px;
    }
    .about-bottom-border {
        width: 50%;
        height: auto;
    }

    .about-row {
        margin-top: 30px;
        flex-wrap: wrap;
    }
    .about-left-col {
        width: 100%;
    }
    .about-right-col {
        padding: 25px;
        width: 100%;
    }
    .about-right-col h4 {
        text-align: center;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .about-right-col h2 {
        text-align: center;
    }
}

@media (min-width: 992px) and (max-width: 1024px) {
    .about-bottom-border {
        width: 20%;
        height: 80%;
    }
    /* .about-banner-section img {
        margin-top: 20px;
        width: 100%;
        height: 500px;
    } */
    .about-row {
        margin-top: 25px;
    }
}
